// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-announcements-and-news-js": () => import("./../../../src/pages/announcements-and-news.js" /* webpackChunkName: "component---src-pages-announcements-and-news-js" */),
  "component---src-pages-campus-life-js": () => import("./../../../src/pages/campus-life.js" /* webpackChunkName: "component---src-pages-campus-life-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-facilities-js": () => import("./../../../src/pages/facilities.js" /* webpackChunkName: "component---src-pages-facilities-js" */),
  "component---src-pages-faculty-js": () => import("./../../../src/pages/faculty.js" /* webpackChunkName: "component---src-pages-faculty-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-programs-js": () => import("./../../../src/pages/programs.js" /* webpackChunkName: "component---src-pages-programs-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-tenders-js": () => import("./../../../src/pages/tenders.js" /* webpackChunkName: "component---src-pages-tenders-js" */),
  "component---src-templates-faculty-js": () => import("./../../../src/templates/faculty.js" /* webpackChunkName: "component---src-templates-faculty-js" */),
  "component---src-templates-hod-js": () => import("./../../../src/templates/hod.js" /* webpackChunkName: "component---src-templates-hod-js" */),
  "component---src-templates-placement-js": () => import("./../../../src/templates/placement.js" /* webpackChunkName: "component---src-templates-placement-js" */),
  "component---src-templates-placementlist-js": () => import("./../../../src/templates/placementlist.js" /* webpackChunkName: "component---src-templates-placementlist-js" */),
  "component---src-templates-programs-js": () => import("./../../../src/templates/programs.js" /* webpackChunkName: "component---src-templates-programs-js" */),
  "component---src-templates-singlepage-js": () => import("./../../../src/templates/singlepage.js" /* webpackChunkName: "component---src-templates-singlepage-js" */)
}

